// MobileNotSupported.js
import React from 'react';
import { Overlay, Text } from '@mantine/core';

function MobileNotSupported() {
    return (
        <Overlay
            visible={true}
            style={{
                display: 'flex',
                justifyContent: 'flex-start', // Align content to the top
                // alignItems: 'center',
                textAlign: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                color: 'black',
                padding: '20px',
                height: '90vh',
                width: '90vw',
            }}
        >
            <div>
                <Text fw={600} style={{ fontSize: '28px', paddingBottom: '8px', paddingTop: '20px', fontFamily: 'Inter', color: 'black' }}>LeetPrompt</Text>
                <Text px={10} fw={400} style={{ fontSize: '18px', fontFamily: 'Inter' }}>Solve challenges using language models</Text>

                <p style={{marginTop: '30vh', fontWeight: '600'}}>This site is not optimized for mobile devices. Please use a laptop or desktop for the best experience.</p>
            </div>
        </Overlay>
    );
}

export default MobileNotSupported;