import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './screens/Home';
import Home2 from './screens/Home2';
import MobileNotSupported from './screens/MobileNotSupported';


function App() {
  const [isMobile, setIsMobile] = useState(false);
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
  };

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  return (
    <Router>
      <Routes>
        {isMobile ? (
          <Route path="*" element={<MobileNotSupported />} />
        ) : (
          <>
            <Route path="/:study/:slug" element={<Home2 />} />
            <Route path="/:slug" element={<Home2 />} />
            <Route path="/:study" element={<Home2 />} />
            <Route path="/" element={<Home2 />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;