import { ActionIcon, Button, Card, Container, Flex, Grid, Group, Input, LoadingOverlay, MantineProvider, Modal, PasswordInput, Table, Tabs, Text, Tooltip } from '@mantine/core';
import { IconBrandOpenai, IconCircleCheckFilled, IconCircleFilled, IconCircleXFilled, IconCloudUpload, IconHistoryToggle, IconPlayerPlayFilled, IconPlayerSkipBackFilled, IconPlayerSkipForwardFilled, IconPrompt, IconStarFilled, IconTrophy, IconUserFilled } from '@tabler/icons-react';
import { createSavedPrompt, fetchDefaultPrompt, fetchProblemBySlug, fetchProblemStandings, fetchProblems, fetchSavedPrompt, fetchUserStandings, getCSRF, getSession, getStandings, getSubmissionsByUser, getUserPoints, login, runSubmitProblem, runTestProblem, whoami, fetchStudyProblems, isValidStudy, isUsernameTaken } from '../api/api';
import { useDisclosure } from '@mantine/hooks';

import { getCookie, removeCookie, setCookie } from './cookieUtils';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
import Joyride, { STATUS } from 'react-joyride';
import CodeMirror from '@uiw/react-codemirror';
import 'react-toastify/dist/ReactToastify.css';
import { EditorView } from "@codemirror/view";
import ReactMarkdown from 'react-markdown';
import WebFont from 'webfontloader';
import DOMPurify from 'dompurify';

const steps = [
    {
        target: '.details-tab',
        content: 'This is the problem description, which also contains some examples of expected inputs and outputs!',
    },
    {
        target: '.cm-overall',
        content: 'This is the prompt box, where you can write and test prompts that will be used to solve the problem.',
    },
    {
        target: '.submit-card',
        content: 'This is the submit area, where you can submit your prompt against hidden test cases.',
    },
]


function Home2({ onLogout }) {
    const { slug: initialSlug, study: initialStudy } = useParams();
    const [slug, setSlug] = useState(initialSlug);
    const [study, setStudy] = useState(initialStudy);
    const navigate = useNavigate();
    // const initialFetchRef = useRef(false);
    const [problems, setProblems] = useState(null);
    const [currProblem, setCurrProblem] = useState(null);
    const [editorText, setEditorText] = useState(null);
    const [testInputValue, setTestInputValue] = useState(currProblem?.sample_input || "");
    const [testOutputValue, setTestOutputValue] = useState("");
    const [testOutputValueRegexed, setTestOutputValueRegexed] = useState("");
    const [regexFormat, setRegexFormat] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [regexDisplayBefore, setRegexDisplayBefore] = useState("Output: ");
    // eslint-disable-next-line no-unused-vars
    const [regexDisplayAfter, setRegexDisplayAfter] = useState("");
    const [isRunning, setIsRunning] = useState(false);
    const [isRunningSubmit, setIsRunningSubmit] = useState(false);
    const [hiddenTestCases, setHiddenTestCases] = useState(currProblem?.testcases || []);
    const [submitResults, setSubmitResults] = useState([]);
    const [openedLogin, { open, close }] = useDisclosure(false);
    const [openedStandings, { open: openStandings, close: closeStandings }] = useDisclosure(false);
    const [csrfTkn, setCsrfTkn] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("");
    const [currPlayerUsername, setCurrPlayerUsername] = useState("");
    const [userPoints, setUserPoints] = useState(null);
    const [standings, setStandings] = useState(null);
    const [submissions, setSubmissions] = useState(null);
    const [problemStandings, setProblemStandings] = useState(null);
    const [userStandings, setUserStandings] = useState(null);
    const [rendered, setRendered] = useState(false);
    const [loading, setLoading] = useState(false);

    const [tutorial2Passed, setTutorial2Passed] = useState(() => {
        return getCookie('tutorial2Passed') === 'true';
    });

    useEffect(() => {
        if (tutorial2Passed) {
            setCookie('tutorial2Passed', 'true', { expires: 365 }); // Set the cookie to expire in 365 days
        } else {
            removeCookie('tutorial2Passed'); // Remove the cookie
        }
    }, [tutorial2Passed]);

    function debounce(func, wait, setLoading) {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            setLoading(true);
            timeout = setTimeout(() => {
                func.apply(context, args);
                setLoading(false);
            }, wait);
        };
    }
    
    const handleNext = () => {
        if (problems && problems.length > 0) {
            const currentIndex = problems.findIndex(problem => problem.slug === currProblem?.slug);
            const nextIndex = (currentIndex + 1) % problems.length;
            createSavedPrompt(currProblem.slug, { prompt_data: editorText }, csrfTkn);
            setCurrProblem(problems[nextIndex]);
        }
    };
    
    const handlePrevious = () => {
        if (problems && problems.length > 0) {
            const currentIndex = problems.findIndex(problem => problem.slug === currProblem?.slug);
            const previousIndex = (currentIndex - 1 + problems.length) % problems.length;
            createSavedPrompt(currProblem.slug, { prompt_data: editorText }, csrfTkn);
            setCurrProblem(problems[previousIndex]);
        }
    };
    
    const debouncedHandleNext = debounce(handleNext, 500, setLoading);
    const debouncedHandlePrevious = debounce(handlePrevious, 500, setLoading);

    // const handleMostPopular = () => fetchProblemByStrategy(2, setCurrProblem);
    // const handleMostDifficult = () => fetchProblemByStrategy(3, setCurrProblem);
    // const handleMostRecent = () => fetchProblemByStrategy(4, setCurrProblem);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Inter:600', 'Inter:400', 'Fira Sans']
            }
        });
    }, []);

    useEffect(() => {
        if (initialSlug && initialSlug.startsWith('study')) {
            setSlug(initialStudy);
            setStudy(initialSlug);
        }
        setRendered(true);
    }, [initialSlug, initialStudy]);

    useEffect(() => {
        if (study && rendered) {
            isValidStudy(study).then(isValid => {
                if (!isValid) {
                    navigate('/');
                    setStudy(null);
                } else {
                    fetchStudyProblems(setProblems, study, csrfTkn);
                }
            }).catch(error => {
                console.error('Error checking study validity:', error);
            });
        } else if (rendered) {
            fetchProblems(setProblems, csrfTkn);
        }
    }, [study, rendered, navigate]);

    useEffect(() => {
        if (slug && rendered) {
            fetchProblemBySlug(slug, setCurrProblem);
        }
    }, [slug, rendered]);

    useEffect(() => {
        if (problems && !slug) {
            const randomIndex = Math.floor(Math.random() * problems.length);
            setCurrProblem(problems[randomIndex]);
        }
    }, [problems, slug]);

    useEffect(() => {
        if (currProblem) {
            fetchProblemStandings(setProblemStandings);
            fetchUserStandings(setUserStandings);
            setSlug(currProblem.slug);
        }
    }, [currProblem]);

    useEffect(() => {
        if (currProblem && currProblem.slug) {
            if (study) {
                navigate(`/${study}/${currProblem.slug}`, { replace: true });
            } else {
                navigate(`/${currProblem.slug}`, { replace: true });
            }
        }
    }, [currProblem, study, navigate]);

    useEffect(() => {
        if (currProblem) {
            fetchSavedPrompt(currProblem.slug, setEditorText, csrfTkn);
        }
    }, [currProblem]);

    useEffect(() => {
        const regex = new RegExp(`(?<=${regexDisplayBefore}).*(?=${regexDisplayAfter})`);
        setRegexFormat(regex);
    }, [regexDisplayBefore, regexDisplayAfter]);

    useEffect(() => {
        const processedOutput = regexFormat && testOutputValue ? (testOutputValue?.match(new RegExp(regexFormat, 'm'))?.[0] || 'xxxx No match found xxxx') : testOutputValue;
        setTestOutputValueRegexed(processedOutput);
    }, [regexFormat, testOutputValue]);

    useEffect(() => {
        setEditorText(currProblem?.sample_prompt.replace("Input: [[ INPUT ]]", "") || "");
        setHiddenTestCases(currProblem?.testcases || []);
        setTestInputValue(currProblem?.sample_input || "");
        setHiddenTestCases(currProblem?.testcases || []);
        setTestOutputValue("");
        setSubmitResults([]);
    }, [currProblem]);

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            setIsAuthenticated(false);
            setError('Username and password are required.');
            return;
        }
        try {
            await login(e, csrfTkn, username, password, setIsAuthenticated, setUsername, setPassword, setError, userStandings, () => {
                console.log('attempting login...')
            }).then(() => {
                setError("");
                close();
            });
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    useEffect(() => {
        getSession(setIsAuthenticated, setCsrfTkn);
        whoami(setCurrPlayerUsername);
        getCSRF(setCsrfTkn);
        if (isAuthenticated && slug) {
            getStandings(slug, setStandings);
            getSubmissionsByUser(slug, csrfTkn, setSubmissions);
            getUserPoints(csrfTkn, setUserPoints);
        }
    }, [isAuthenticated, slug]);

    function highlightSearchTerm(text, searchTerm) {
        const regex = new RegExp(`(${searchTerm})(.*)`, 'gi');
        return text.replace(regex, (match, p1, p2) => `${p1}<span class="highlighted">${p2}</span>`);
    }

    return (
        <>
            <LoadingOverlay visible={loading || !currProblem} loaderProps={{ color: 'black', variant: 'bars' }} overlayOpacity={0.9} />
            <MantineProvider theme={{ primaryColor: 'dark' }}>

                <div style={{ backgroundColor: "#f5f5f5", height: '100vh' }}>
                    {!tutorial2Passed && (
                        <Joyride
                            steps={steps}
                            continuous={true}
                            hideBackButton={true}
                            locale={{ last: 'Finish' }}
                            showSkipButton={true}
                            callback={({ status }) => {
                                if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
                                    window.localStorage.setItem('tutorial2Passed', 'true');
                                    setTutorial2Passed(true);
                                }
                            }}
                        />
                    )}
                    <Modal opened={openedLogin} onClose={close} title={"Sign In"}>
                        <Text size="xs" pb={10} c="dimmed">
                            Login here. If you don't have an account, we'll create one for you.
                        </Text>
                        <Input.Wrapper label="Username" description="" error="" mt={8}>
                            <Input value={username} onChange={(e) => setUsername(e.target.value)} />
                        </Input.Wrapper>
                        <Input.Wrapper label="Password" description="" error="" mt={8}>
                            <PasswordInput value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Input.Wrapper>

                        {true && (
                            <Flex justify="center">
                                <Text size="xs" mt={10} c="red" style={{fontSize: 14, fontWeight: 'bold'}}>
                                    {error}
                                </Text>
                            </Flex>
                        
                        )}

                        <Button variant="filled" mt={30} mb={10} onClick={(e) => handleLoginSubmit(e, username, password)}>Sign In / Sign Up</Button>
                    </Modal>

                    <Container fluid px={60} pt={20}>
                        <Flex style={{ justifyContent: 'space-between', paddingTop: '10px' }} align="center">
                            <div style={{ maxWidth: '200px', borderRadius: '25px', padding: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Flex gap="md" style={{ justifyContent: 'space-between' }}>
                                        <Tooltip label="Previous" position="bottom" offset={15} withArrow>
                                            <ActionIcon radius="xl" variant="filled" color="gray" onClick={debouncedHandlePrevious}>
                                                <IconPlayerSkipBackFilled size={14} />
                                            </ActionIcon>
                                        </Tooltip>
                                        <Tooltip label="Next" position="bottom" offset={15} withArrow>
                                            <ActionIcon radius="xl" variant="filled" color="gray" onClick={debouncedHandleNext}>
                                                <IconPlayerSkipForwardFilled size={14} />
                                            </ActionIcon>
                                        </Tooltip>
                                    </Flex>
                                </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <Text fw={600} style={{ fontSize: '28px', paddingBottom: '8px', fontFamily: 'Inter' }}>LeetPrompt</Text>
                                <Flex align='center' style={{ paddingBottom: '40px', color: '#777' }}>
                                    {/* <IconSwords size={14} stroke={2.7}/> */}
                                    <Text px={10} fw={400} style={{ fontSize: '18px', fontFamily: 'Inter' }}>Solve challenges using language models</Text>
                                    {/* <IconSwords size={14} stroke={2.7}/> */}
                                </Flex>

                            </div>
                            <Modal opened={openedStandings} onClose={closeStandings} size="70%">
                                <Grid>
                                    <Grid.Col span={6}>
                                        <Text size="lg" fw={700} style={{ paddingBottom: '20px' }}>Problems</Text>
                                        <Table striped highlightOnHover pt="sm">
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Ref</th>
                                                    <th>Solve %</th>
                                                    <th>Posted On</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {problemStandings && problemStandings?.map((problem, index) => (
                                                    <tr key={index}>
                                                        <td>{problem.title}</td>
                                                        <td>Ian Badfellow</td>
                                                        <td>{problem.solve_rate_str}</td>
                                                        <td>{problem.posting_date.split('T')[0]}</td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Grid.Col>
                                    <Grid.Col span={6}>
                                        <Text size="lg" fw={700} style={{ paddingBottom: '20px' }}>Player Standings</Text>
                                        <Table striped highlightOnHover pt="sm">
                                            <thead>
                                                <tr>
                                                    <th>Rank</th>
                                                    <th>User</th>
                                                    <th>Points</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userStandings && userStandings?.map((standing, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{standing.username}</td>
                                                        <td>{standing.points}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Grid.Col>
                                </Grid>
                            </Modal>


                            {(isAuthenticated && !currPlayerUsername?.startsWith('anon')) ? (
                                <Card withBorder shadow="md" p={8} radius="xl" style={{ background: '', cursor: 'pointer' }} onClick={openStandings} >
                                    <Flex align="center">
                                        <Text fw={500} size={14} pl={4}></Text>
                                        <IconStarFilled size={16} style={{ color: '#FFD700' }} />
                                        <Text fw={500} size={14} pr={20} pl={4}>{userPoints}</Text>
                                        <IconUserFilled size={16} />
                                        <Text fw={500} size={14} pr={10} pl={4}>{currPlayerUsername}</Text>
                                        {/* <Button variant="subtle" style={{ backgroundColor: '#f5f5f5' }}  radius="xl" size="xs" leftIcon={
										<IconUserFilled size={16}/>
									}>{currPlayerUsername}</Button> */}
                                    </Flex>

                                </Card>

                            ) : (
                                <div style={{ maxWidth: '200px', borderRadius: '25px', padding: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#fff' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button variant="default" radius="xl" size="xs" onClick={open}>Sign in to record progress</Button>
                                    </div>
                                </div>
                            )}

                        </Flex>

                        <Group >
                            <h2>{currProblem?.title}</h2>
                            {/* <DifficultyBadge difficulty={currProblem?.difficulty} /> */}
                            <Group style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                                <Text size="sm">
                                    Solved by <Text span fw={700}>{currProblem?.total_solved}</Text> &nbsp;|&nbsp; Players <Text span fw={700}>{currProblem?.total_attempts}</Text> &nbsp;|&nbsp; Solve Rate <Text span fw={700}>{currProblem?.solve_rate}</Text> &nbsp;|&nbsp; Total Tries <Text span fw={700}>{currProblem?.total_submissions}</Text>
                                </Text>
                                {/* SHARE BUTTON */}
                                {/* <Button variant="default" size="md" style={{ marginRight: '10px', borderRadius: 8, border: '2px solid black', background: 'linear-gradient(to right, #84fab0, #8ed5f1)' }} onClick={handleShareClick}>Share</Button> */}
                            </Group>
                        </Group>
                        <Grid>
                            <Grid.Col span={5}>

                                <Tabs defaultValue="details" variant="outline">
                                    <Tabs.List>
                                        <Tabs.Tab value="details" className='details-tab'>
                                            <Text fw={700} size={12} style={{ letterSpacing: '.2px' }} pl={4}>
                                                DETAILS
                                            </Text>
                                            {/* <Text fw={500}>Details</Text> */}
                                        </Tabs.Tab>
                                        <div style={{ flex: 1 }}></div>
                                        <Tabs.Tab value="submissions" disabled={(!isAuthenticated || currPlayerUsername?.startsWith('anon'))}>
                                            <IconHistoryToggle size={18} />
                                        </Tabs.Tab>
                                        <Tabs.Tab value="standings" disabled={(!isAuthenticated || currPlayerUsername?.startsWith('anon'))}>
                                            <IconTrophy size={18} />
                                        </Tabs.Tab>
                                    </Tabs.List>

                                    <Tabs.Panel value="details">
                                        <Card shadow="lg" className="left-card details-card">
                                            <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                                                <ReactMarkdown children={currProblem?.description} />
                                            </div>
                                        </Card>
                                    </Tabs.Panel>
                                    <Tabs.Panel value="submissions">
                                        <Card shadow="lg" className="left-card submissions-card">
                                            <Table striped highlightOnHover pt="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Rank</th>
                                                        <th>Points</th>
                                                        <th>Length</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {submissions && submissions?.map((submission, index) => (
                                                        <tr key={index}>
                                                            <td>{submission.rank}</td>
                                                            <td>{submission.points}</td>
                                                            <td>{submission.length}</td>
                                                            <td>{submission.timestamp.split(' [')[0]}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Tabs.Panel>
                                    <Tabs.Panel value="standings">
                                        <Card shadow="lg" className="left-card standings-card">
                                            <Table striped highlightOnHover pt="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Standing</th>
                                                        <th>User</th>
                                                        <th>Points</th>
                                                        <th>Length</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {standings && standings?.map((standing, index) => (
                                                        <tr key={index}>
                                                            <td>{standing.standing}</td>
                                                            <td>{standing.user}</td>
                                                            <td>{standing.points}</td>
                                                            <td>{standing.length}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Tabs.Panel>
                                </Tabs>
                            </Grid.Col>
                            <Grid.Col span={7}>
                                <Tabs defaultValue="details" variant="outline">
                                    <Tabs.List>
                                        <Tabs.Tab value="details" className='details-tab'>
                                            <Flex align="center" >
                                                <IconPrompt size={16} stroke={3} />
                                                <Text fw={700} size={12} style={{ letterSpacing: '.2px' }} pl={4}>
                                                    PLAYGROUND
                                                </Text>
                                            </Flex>
                                        </Tabs.Tab>

                                        <Flex align="center" pl={12}>
                                            <Text fw={600} size={10} pr={6} style={{ color: '#999' }}>
                                                USING
                                            </Text>
                                            <IconBrandOpenai size={13} stroke={1.8} style={{ color: '#999' }} />
                                            <Text fw={600} size={10} pl={4} style={{ color: '#999' }}>
                                                GPT-3.5-TURBO
                                            </Text>
                                        </Flex>
                                    </Tabs.List>

                                    <Tabs.Panel value="details">
                                        <Card shadow="sm" radius="lg" style={{ paddingTop: '10px' }} className="playground-card">
                                            <div style={{ position: 'relative', paddingTop: '8px' }}>

                                                <div className="cm-overall">
                                                    <CodeMirror
                                                        value={editorText || currProblem?.sample_prompt}
                                                        height='30vh'
                                                        extensions={[EditorView.lineWrapping]}
                                                        options={{ lineWrapping: true }}
                                                        onChange={(value, viewUpdate) => setEditorText(value)}
                                                        basicSetup={{ lineNumbers: false, foldGutter: false }}
                                                        style={{ fontSize: '14px' }}
                                                    />
                                                    <div className="cm-below">
                                                        <LoadingOverlay visible={isRunning} zIndex={1000} loaderProps={{ color: 'orange', variant: 'dots' }} overlayOpacity={0.6} />
                                                        <Flex style={{ justifyContent: 'space-between' }}>
                                                            <div>
                                                                <Flex align="center" >
                                                                    <Text className="cm-input">Input:</Text>
                                                                    <Input value={testInputValue} size="xs"
                                                                        onChange={(event) => setTestInputValue(event.target.value)}
                                                                        className="cm-input-box"
                                                                        variant="filled"
                                                                    />

                                                                </Flex>
                                                                {testOutputValue?.length > 0 && (

                                                                    <div className="cm-output">
                                                                        {isRunning ? "Loading..." : <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightSearchTerm(testOutputValue, regexDisplayBefore)) }} />}
                                                                    </div>


                                                                )}
                                                            </div>
                                                            <div>
                                                                <Button loading={isRunning} loaderProps={{ type: 'dots' }} radius={9} variant="outline" size="xs" color="orange" onClick={() => {
                                                                    runTestProblem(currProblem?.slug, editorText, regexFormat, testInputValue, setTestOutputValue, setIsRunning, csrfTkn);
                                                                }} leftIcon={
                                                                    <IconPlayerPlayFilled size={14} />
                                                                }>	 Test
                                                                </Button>

                                                            </div>
                                                        </Flex>


                                                    </div>
                                                </div>

                                                {/* {testOutputValueRegexed && ( */}

                                                <Flex align="flex-end" style={{ justifyContent: 'space-between' }} pl={5} pr={5}>
                                                    <div className="cm-status">
                                                        {!testOutputValueRegexed ? (
                                                            <Text size="xs" style={{ color: '#9e9e9e' }} fw={600}>
                                                                <Flex align="center" gap="xs">
                                                                    <IconCircleFilled size={12} strokeWidth={3} /> Test your prompt before submitting.
                                                                </Flex>
                                                            </Text>
                                                        ) : (
                                                            testOutputValue?.length > 0 && testOutputValue.startsWith('Output: ') ? (
                                                                <Text size="xs" style={{ color: '#66bb6a' }} fw={600}>
                                                                    <Flex align="center" gap="xs">
                                                                        <IconCircleFilled size={12} strokeWidth={3} /> Format OK
                                                                    </Flex>
                                                                </Text>
                                                            ) : (
                                                                <Text size="xs" style={{ color: '#ef5350' }} fw={600}>
                                                                    <Flex align="center" gap="xs">
                                                                        <IconCircleFilled size={12} strokeWidth={3} /> Result does not start with "Output: "
                                                                    </Flex>
                                                                </Text>
                                                            )
                                                        )}
                                                    </div>

                                                    <Button
                                                        variant="light"
                                                        color="red"
                                                        size={0}
                                                        onClick={() => fetchDefaultPrompt(currProblem?.slug, setEditorText, csrfTkn)}
                                                        style={{ marginLeft: '10px', fontSize: '10px', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '7px', paddingRight: '7px' }}

                                                    >
                                                        Reset Playground
                                                    </Button>


                                                </Flex>

                                            </div>
                                        </Card>
                                        <Card shadow="sm" radius="lg" style={{ marginTop: '15px' }} className="submit-card">
                                            <div>
                                                <Flex style={{ justifyContent: 'space-between' }} align="center">
                                                    <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>




                                                        <Flex align="center">

                                                            <Button radius="md" loading={isRunningSubmit} loaderProps={{ type: 'dots' }} variant="filled" mr={20} color="dark" size="sm" onClick={() => runSubmitProblem(currProblem?.slug, editorText, regexFormat, testInputValue, setSubmitResults, setIsRunningSubmit, csrfTkn, setUserPoints)} leftIcon={
                                                                <IconCloudUpload size={14} />
                                                            }
                                                                disabled={testOutputValue.startsWith('Output: ') ? false : true}
                                                            > Submit
                                                            </Button>

                                                            {/* <Flex align="center" pt={30} pb={5} style={{ color: '#90a4ae' }}>
                                                                <IconTestPipe size={16} stroke={2.5} />
                                                                <Text fw={700} size={12} style={{ letterSpacing: '.2px' }} pl={4}>
                                                                    TESTCASES
                                                                </Text>
                                                            </Flex> */}

                                                            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', paddingTop: 7 }}>
                                                                {Object.keys(submitResults).length === 0 ? (
                                                                    hiddenTestCases.map((testCaseId, index) => (
                                                                        isRunningSubmit ? (
                                                                            <ActionIcon color="yellow"
                                                                                key={index}
                                                                            >
                                                                                <IconCircleFilled size={18} />
                                                                            </ActionIcon>
                                                                        ) : (
                                                                            <ActionIcon
                                                                                key={index}
                                                                            >
                                                                                <IconCircleFilled size={18} style={{ color: '#e0e0e0' }} />
                                                                            </ActionIcon>
                                                                        )
                                                                    ))
                                                                ) : (
                                                                    Object.keys(submitResults).map((testCaseId, index) => {
                                                                        const result = submitResults[testCaseId];

                                                                        if (result === 'correct') {
                                                                            return (
                                                                                <ActionIcon
                                                                                    key={index}
                                                                                >
                                                                                    <IconCircleCheckFilled size={18} style={{ color: '#4caf50' }} />
                                                                                </ActionIcon>
                                                                            );
                                                                        }
                                                                        if (result === 'wrong') {
                                                                            return (
                                                                                <ActionIcon
                                                                                    key={index}
                                                                                >
                                                                                    <IconCircleXFilled size={18} style={{ color: '#f44336' }} />
                                                                                </ActionIcon>
                                                                            );
                                                                        }
                                                                        return (
                                                                            <ActionIcon
                                                                                key={index}
                                                                            >
                                                                                <IconCircleFilled size={18} color='#aaa' />
                                                                            </ActionIcon>
                                                                        );
                                                                    })
                                                                )}

                                                            </div>
                                                        </Flex>

                                                    </div>
                                                </Flex>
                                            </div>
                                        </Card>
                                    </Tabs.Panel>
                                </Tabs>
                            </Grid.Col>
                        </Grid>
                    </Container>
                </div>
            </MantineProvider>

            {/* <ToastContainer /> */}
        </>
    );
}

export default Home2;