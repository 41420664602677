import axios from 'axios';

const origin = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : window.location.origin;

export const fetchProblems = async (setProblems, csrfTkn) => {
    try {
        const response = await axios.get(origin + '/get_all_problems/', {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfTkn
            },
            withCredentials: true
        });
        setProblems(response.data.problems)
    } catch (error) {
        console.error('fetch failed:', error);
    }
}

export const isValidStudy = async (study) => {
    try {
        const response = await axios.get(`${origin}/is_valid_study/${study}/`);
        // console.log('Study validity:', response.data.is_valid);
        return response.data.is_valid;
    } catch (error) {
        console.error('Error checking study validity:', error);
        throw error;
    }
};

export const fetchStudyProblems = async (setProblems, study, csrfTkn) => {
    try {
        const response = await axios.get(origin + `/get_study_problems/${study}/`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfTkn
            },
            withCredentials: true
        });
        setProblems(response.data.problems)
    } catch (error) {
        console.error('fetch failed:', error);
    }
}

export const fetchSavedPrompt = async (slug, setEditorText, csrfTkn) => {
    try {
        const response = await axios.get(origin + `/get_most_recent_prompt/${slug}/`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfTkn
            },
            withCredentials: true
        });
        if (response.data.failed) {
            console.error('Failed to fetch the most recent prompt:', response.data.failed);
        } else {
            setEditorText(response.data.prompt.replace("Input: [[ INPUT ]]", ""))
            // setEditorText(response.data.prompt)
            // console.log(response.data.prompt)
        }
    } catch (error) {
        console.error('fetch failed:', error);
    }
}

export const fetchDefaultPrompt = async (slug, setEditorText, csrfTkn) => {
    try {
        const response = await axios.get(origin + `/get_default_prompt/${slug}/`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfTkn
            },
            withCredentials: true
        });
        if (response.data.failed) {
            console.error('Failed to fetch the default prompt:', response.data.failed);
        } else {
            setEditorText(response.data.prompt.replace("Input: [[ INPUT ]]", ""))
            // setEditorText(response.data.prompt)
            // console.log(response.data.prompt)
        }
    } catch (error) {
        console.error('fetch failed:', error);
    }
}

export const createSavedPrompt = async (slug, promptData, csrfTkn) => {
    try {
        const response = await axios.post(origin + `/create_saved_prompt/${slug}/`, promptData, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfTkn
            },
            withCredentials: true
        });
        if (response.data.failed) {
            console.error('Failed to create the saved prompt:', response.data.failed);
        } else {
            // console.log('Saved prompt created successfully:', response.data);
        }
    } catch (error) {
        console.error('Save failed:', error);
    }
};

export const fetchProblemByStrategy = async (strategy, setCurrProblem) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(origin + `/get_problem_by_strat/${strategy}/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setCurrProblem(response.data);
    } catch (error) {
        console.error('fetch failed:', error);
    }
};

export const fetchProblemBySlug = async (slug, setCurrProblem) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(origin + `/problem_by_slug/${slug}/`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setCurrProblem(response.data);
    } catch (error) {
        console.error('fetch failed:', error);
    }
};

export const runTestProblem = async (slug, text, rgx, input, setTestOutputValue, setIsRunning, csrfTkn) => {
    setIsRunning(true);
    try {
        const promptText = text + '\nInput: [[ INPUT ]]';
        const regex = rgx;
        const mask = input;
        const model = 'gpt-3.5-turbo';
        const temperature = 0.7;
        const maxLength = 300;
        const topP = 1;
        const frequencyPenalty = 0;
        const presencePenalty = 0;

        const problemData = {
            text: promptText,
            regex: regex,
            mask: mask,
            model: model,
            temperature: temperature,
            max_length: maxLength,
            top_p: topP,
            frequency_penalty: frequencyPenalty,
            presence_penalty: presencePenalty,
        };
        const response = await axios.post(origin + `/problem_run_react/${slug}/`, problemData, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfTkn
            },
            withCredentials: true
        });
        setTestOutputValue(response.data);
    } catch (error) {
        console.error('fetch failed:', error);
    } finally {
        setIsRunning(false);
    }
};

export const runSubmitProblem = async (slug, text, rgx, input, setSubmitResults, setIsRunningSubmit, csrfTkn, setUserPoints) => {
    setIsRunningSubmit(true);
    try {
        const promptText = text + '\nInput: [[ INPUT ]]';
        const regex = rgx;
        const mask = input;
        const model = 'gpt-3.5-turbo';
        const temperature = 0.7;
        const maxLength = 300;
        const topP = 1;
        const frequencyPenalty = 0;
        const presencePenalty = 0;

        const problemData = {
            text: promptText,
            regex: regex.toString(),
            mask: mask,
            model: model,
            temperature: temperature,
            max_length: maxLength,
            top_p: topP,
            frequency_penalty: frequencyPenalty,
            presence_penalty: presencePenalty,
        };
        const response = await axios.post(origin + `/problem_submit_react/${slug}/`, problemData, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfTkn
            },
            withCredentials: true
        });
        setSubmitResults(response.data.testcasestatus);
        getUserPoints(csrfTkn, setUserPoints);
    } catch (error) {
        console.error('fetch failed:', error);
    } finally {
        setIsRunningSubmit(false);
    }
};

export const getStandings = async (slug, setStandings) => {
    try {
        const response = await axios.get(origin + `/get_standings_problem/${slug}/`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        setStandings(response.data.standings);
    } catch (error) {
        console.error('fetch failed:', error);
    }
}

export const getSubmissionsByUser = async (slug, csrfTkn, setSubmissions) => {
    try {
        const response = await axios.get(origin + `/get_submissions_user_problem/${slug}/`, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfTkn,
            },
            withCredentials: true
        });
        setSubmissions(response.data.submissions);
        // console.log(response.data);
    } catch (error) {
        console.error('fetch failed:', error);
    }
}

export const fetchUserStandings = async (setUserStandings) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(origin + '/user_standings/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setUserStandings(response.data.standings);
        // console.log(response.data.standings);
    } catch (error) {
        console.error('fetch failed:', error);
    }
}

export const fetchProblemStandings = async (setProblemStandings) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(origin + '/problem_standings/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setProblemStandings(response.data.standings);
        // console.log(response.data.standings);
    } catch (error) {
        console.error('fetch failed:', error);
    }
}

export const isUsernameTaken = async (csrfTkn, username) => {
    try {
        const response = await axios.get(`${origin}/api/check-username/`, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfTkn,
            },
            withCredentials: true,
            params: {
                username: username
            }
        });
        console.log(response.data);
        // return response.data.detail === 'Username is taken.';
    } catch (error) {
        console.error('Error checking username:', error);
        throw error;
    }
};

export const getUserPoints = async (csrfTkn, setUserPoints) => {
    try {
        const response = await axios.get(origin + `/get_user_points/`, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfTkn,
            },
            withCredentials: true
        });
        setUserPoints(response.data.points);
    } catch (error) {
        console.error('fetch failed:', error);
    }
}

export const getCSRF = (setCsrfTkn) => {
    fetch((origin + "/api/csrf/"), {
        credentials: "include",
    })
        .then((res) => {
            let csrfToken = res.headers.get("X-CSRFToken");
            setCsrfTkn(csrfToken);
        })
        .catch((err) => {
            console.log(err);
        });
}

export const getSession = (setIsAuthenticated, setCsrfTkn) => {
    fetch((origin + "/api/session/"), {
        credentials: "include",
    })
        .then((res) => res.json())
        .then((data) => {
            // console.log(data);
            if (data.isAuthenticated) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false)
                getCSRF(setCsrfTkn);
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

export const whoami = (setCurrPlayerUsername) => {
    fetch((origin + "/api/whoami/"), {
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    })
        .then((res) => res.json())
        .then((data) => {
            // console.log("You are logged in as: " + data.username);
            setCurrPlayerUsername(data.username);
        })
        .catch((err) => {
            console.log(err);
        });
}

const isResponseOk = (response) => {
    if (response.status >= 200 && response.status <= 299) {
        return response.json();
    } else {
        throw Error(response.statusText);
    }
}

export const login = (event, csrfTkn, username, password, setIsAuthenticated, setUsername, setPassword, setError, userStandings, onLoginFail) => {
    event.preventDefault();
    return fetch((origin + "/api/login/"), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfTkn,
        },
        credentials: "include",
        body: JSON.stringify({ username: username, password: password }),
    })
        .then(isResponseOk)
        .then((data) => {
            // console.log(data);
            setIsAuthenticated(true);
            setUsername("");
            setPassword("");
            setError("");
            return Promise.resolve();
        })
        .catch((err) => {
            console.log('here')
            const usernameExists = userStandings.some(user => user.username === username);
            if (usernameExists) {
                setError('Username already exists.');
                setIsAuthenticated(false);
                return Promise.reject();
            }
            console.log(err);
            setError("Wrong username or password.")
            if (onLoginFail) {
                onLoginFail();
            }
            return Promise.reject();
        });
}

export const logout = (setIsAuthenticated, setCsrfTkn) => {
    fetch((origin + "/api/logout"), {
        credentials: "include",
    })
        .then(isResponseOk)
        .then((data) => {
            // console.log(data);
            setIsAuthenticated(false);
            getCSRF(setCsrfTkn);
        })
        .catch((err) => {
            console.log(err);
        });
};